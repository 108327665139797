var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl-flex gl-flex-col gl-border-b-1 gl-border-t-1 gl-border-b-solid gl-border-t-solid",class:_vm.optionalClasses},[_c('div',{staticClass:"gl-flex gl-items-center gl-py-3"},[(_vm.$slots['left-action'] /* eslint-disable-line @gitlab/vue-prefer-dollar-scopedslots */)?_c('div',{staticClass:"gl-flex gl-w-7 gl-justify-start gl-pl-2"},[_vm._t("left-action")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gl-flex gl-grow gl-flex-col gl-items-stretch gl-justify-between sm:gl-flex-row"},[_c('div',{staticClass:"gl-mb-3 gl-flex gl-min-w-0 gl-grow gl-flex-col sm:gl-mb-0"},[(
            _vm.$slots['left-primary'] /* eslint-disable-line @gitlab/vue-prefer-dollar-scopedslots */
          )?_c('div',{staticClass:"gl-flex gl-min-h-6 gl-min-w-0 gl-items-center gl-font-semibold gl-text-default"},[_vm._t("left-primary"),_vm._v(" "),(_vm.detailsSlots.length > 0)?_c('gl-button',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],staticClass:"gl-ml-2 gl-hidden sm:gl-block",attrs:{"icon":_vm.toggleDetailsIcon,"aria-label":_vm.toggleDetailsLabel,"size":"small","category":"tertiary","title":_vm.toggleDetailsLabel,"aria-expanded":_vm.isDetailsShown},on:{"click":_vm.toggleDetails}}):_vm._e(),_vm._v(" "),_vm._t("left-after-toggle")],2):_vm._e(),_vm._v(" "),(
            /* eslint-disable-line @gitlab/vue-prefer-dollar-scopedslots */ _vm.$slots[
              'left-secondary'
            ]
          )?_c('div',{staticClass:"gl-flex gl-min-h-6 gl-min-w-0 gl-grow gl-items-center gl-gap-3 gl-text-sm gl-text-subtle"},[_vm._t("left-secondary")],2):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"gl-flex gl-shrink-0 gl-flex-col gl-justify-between gl-text-subtle sm:gl-items-end"},[(
            /* eslint-disable-line @gitlab/vue-prefer-dollar-scopedslots */ _vm.$slots[
              'right-primary'
            ]
          )?_c('div',{staticClass:"gl-flex gl-min-h-6 gl-items-center sm:gl-text-default"},[_vm._t("right-primary")],2):_vm._e(),_vm._v(" "),(
            /* eslint-disable-line @gitlab/vue-prefer-dollar-scopedslots */ _vm.$slots[
              'right-secondary'
            ]
          )?_c('div',{staticClass:"gl-flex gl-min-h-6 gl-items-center gl-text-sm"},[_vm._t("right-secondary")],2):_vm._e()])]),_vm._v(" "),(
        _vm.$slots['right-action'] /* eslint-disable-line @gitlab/vue-prefer-dollar-scopedslots */
      )?_c('div',{staticClass:"gl-flex gl-w-9 gl-justify-end gl-pr-1"},[_vm._t("right-action")],2):_vm._e()]),_vm._v(" "),(_vm.isDetailsShown)?_c('div',{staticClass:"gl-flex"},[_c('div',{staticClass:"gl-w-7"}),_vm._v(" "),_c('div',{staticClass:"gl-mb-3 gl-flex gl-grow gl-flex-col gl-rounded-base gl-bg-gray-10 gl-shadow-inner-1-gray-100"},_vm._l((_vm.detailsSlots),function(row,detailIndex){return _c('div',{key:detailIndex,staticClass:"gl-px-5 gl-py-2",class:{
          'gl-border-t-1 gl-border-default gl-border-t-solid': detailIndex !== 0,
        }},[_vm._t(row)],2)}),0),_vm._v(" "),_c('div',{staticClass:"gl-w-9"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }